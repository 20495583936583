<template>
  <tagby-sidebar
    :visible="visible"
    width="30%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      구매자 수정
    </template>
    <template #content>
      <b-row>
        <b-col>
          <b-form-group
            label="이름"
          >
            <b-form-input
              :value="shopperName"
              @input="$emit('input-shopper-name', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="!isValid"
            @click="$emit('ok')"
          >
            <b-spinner
              v-if="isSaving"
              small
            />
            확인
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$emit('hidden')"
          >
            취소
          </b-button>
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    shopperName: {
      type: String,
      default: '',
    },
  },
}
</script>
