import {
  ref,
  onMounted,
  onUnmounted,
  toRefs,
} from '@vue/composition-api'
import store from '@/store'
import storeModule from './TagbyMemoStoreModule'

export default props => {
  const USER_APP_STORE_MODULE_NAME = 'tagby-memo'
  const content = ref('')

  const {
    table,
    idx,
    saveThen,
    saveCatch,
    saveFinally,
  } = toRefs(props)

  onMounted(() => {
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)
    }
  })
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    }
  })

  const save = () => {
    store.dispatch(`${USER_APP_STORE_MODULE_NAME}/memoPost`,
      {
        table: table.value,
        idx: idx.value,
        content: content.value,
      })
      .then(() => {
        saveThen.value()
      })
      .catch(() => {
        saveCatch.value()
      })
      .finally(() => {
        content.value = ''
        saveFinally.value()
      })
  }
  return {
    save,
    content,
  }
}
