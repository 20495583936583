<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="30%"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            송장입력
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <div
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="배송업체"
                >
                  <v-select
                    v-model="newShipmentCompany"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="shipmentCompanyOptions"
                    :reduce="val => val.label"
                    class="invoice-filter-select"
                    placeholder="검색 항목 선택"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="송장번호"
                >
                  <b-form-input
                    v-model="newTrackingNo"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  :disabled="!isValidForm || isSaving"
                  @click="onSave"
                >
                  <b-spinner
                    v-if="isSaving"
                    small
                  />

                  저장
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  VBToggle,
  BSidebar,
  BTable,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BTable,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,
    BSpinner,
    vSelect,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: null,
    },
    shipmentCompany: {
      type: String,
      default: '',
    },
    trackingNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newShipmentCompany: this.shipmentCompany,
      newTrackingNo: this.trackingNo,
      shipmentCompanyOptions: [],
      isSaving: false,
    }
  },
  computed: {
    isValidForm: {
      get() {
        return (this.trackingNo !== this.newTrackingNo) || (this.shipmentCompany !== this.newShipmentCompany.label)
      },
    },
  },
  mounted() {

  },
  methods: {
    getShimentCompanyOptions() {
      store
        .dispatch('app-invoice/fetchShipmentCompanyList')
        .then(response => {
          console.log(response.data)
          this.shipmentCompanyOptions = Object.entries(response.data.data).map(data => ({ label: data[1], value: data[0] }))
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '배송업체 목록을 가져오는데 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onHidden() {
      this.$emit('on-hidden')
    },
    onShown() {
      console.log('shown')
      this.getShimentCompanyOptions()
    },
    onSave() {
      console.log(this.newShipmentCompany)
      this.isSaving = true
      store
        .dispatch('app-invoice/trackingNoOrder', {
          order_no: this.orderNo,
          shipment_company: this.newShipmentCompany,
          tracking_no: this.newTrackingNo,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '송장번호 업데이트에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.$emit('refresh-detail')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '송장번호 업데이트에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
