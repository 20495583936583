<template>
  <b-modal
    id="tagby-memo"
    cancel-variant="outline-secondary"
    ok-title="확인"
    cancel-title="취소"
    centered
    title="메모"
    @ok="save"
  >
    <b-form-group>
      <label for="memo-content">내용</label>
      <b-form-textarea
        id="memo-content"
        v-model="content"
        rows="8"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import viewModel from './TagbyMemoViewModel'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    idx: {
      type: Number,
      required: true,
    },
    saveThen: {
      type: Function,
      default: () => {},
    },
    saveCatch: {
      type: Function,
      default: () => {},
    },
    saveFinally: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const {
      save,
      content,
    } = viewModel(props)
    return {
      save,
      content,
    }
  },
}
</script>
