<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="30%"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            배송정보 수정
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <div>
            <b-row>
              <b-col>
                <b-form-group label="주소">
                  <b-form-input
                    v-model="newAddress"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="상세주소">
                  <b-form-input
                    v-model="newAddressDetail"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="받는사람">
                  <b-form-input
                    v-model="newRecipientName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="메시지">
                  <b-form-input
                    v-model="newMessage"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="전화번호">
                  <b-form-input
                    v-model="newPhone"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="개인통관고유번호">
                  <b-form-input
                    v-model="newPersonalCustomsId"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="onSave"
                >
                  저장
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSidebar,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store'


export default defineComponent({
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },

  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    deliveryIdx: {
      type: Number,
    },
    address: {
      type: String,
    },
    addressDetail: {
      type: String,
    },
    recipientName: {
      type: String,
    },
    message: {
      type: String,
    },
    phone: {
      type: String,
    },
    personalCustomsId: {
      type: String,
    },
  },

  setup(props, context) {
    const newAddress = ref(props.address)
    const newAddressDetail = ref(props.addressDetail)
    const newRecipientName = ref(props.recipientName)
    const newMessage = ref(props.message)
    const newPhone = ref(props.phone)
    const newPersonalCustomsId = ref(props.personalCustomsId)
    
    const onHidden = () => {
      context.emit('on-hidden')
    }
    const onShown = () => {}

    const toast = useToast()
    const onSave = () => {
      const phone = newPhone.value.replace('-', '')

      store
        .dispatch('app-invoice/updateDelivery', {
          idx: props.deliveryIdx,
          address: newAddress.value,
          address_detail: newAddressDetail.value,
          recipient_name: newRecipientName.value,
          message: newMessage.value,
          personal_customs_id: newPersonalCustomsId.value,
          phone,
        })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '배송정보 수정에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          context.emit('refresh-detail')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '배송정보 수정에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {
      onHidden,
      onShown,
      onSave,

      newAddress,
      newAddressDetail,
      newRecipientName,
      newMessage,
      newPhone,
      newPersonalCustomsId,
    }
  }
})

</script>

<style lang="scss">

</style>
