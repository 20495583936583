import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import router from '@/router'

export default function orderViewModel() {
  // Use toast
  const toast = useToast()
  const orderData = ref(null)
  const orderGroupData = ref(null)
  const productData = ref(null)
  const vendorData = ref(null)
  const optionData = ref(null)
  const optionTextData = ref(null)
  const deliveryData = ref(null)
  const chargeData = ref(null)
  const payData = ref(null)
  const orderGroupFields = [
    'idx',
    { key: 'order_no', label: 'order_group_no' },
    'store',
    'name',
    'phone',
    { key: 'total_amount', label: 'amount' },
    'state',
  ]
  const optionTextFields = [
    { key: 'category', label: '구성' },
    { key: 'content', label: '이름' },
    { key: 'add_price', label: '추가금액' },
  ]
  const chargeFields = [
    // 'idx',
    'title',
    'amount',
    'created_at',
  ]
  const payFields = [
    'details',
    // 'idx',
    'pay_type',
    // 'pid',
    'amount',
    'state',
    'created_at',
  ]
  const showUpdateDeliverySidebar = ref(false)
  const showCancelSidebar = ref(false)
  const showTrackingNoSidebar = ref(false)
  const showAddChargeSidebar = ref(false)
  const showAddPaymentSidebar = ref(false)
  const step = ref({
    hasTrackingNo: false,
    hasSettle: false,
    inputAmount: false,
    cancelConfirm: false,
    takebackConfirm: false,
  })
  const orderNo = computed(() => orderData.value?.order_no)
  const orderGroupNo = computed(() => orderGroupData.value?.[0]?.order_no)
  const parseDeliveryState = state => {
    let parseState
    switch (state) {
      case 'WAIT':
        parseState = '대기중'
        break
      case 'READY':
        parseState = '배송준비중'
        break
      case 'DELIVERY':
        parseState = '배송중'
        break
      case 'DONE':
        parseState = '배송완료'
        break
      case 'WITHDRAW':
        parseState = '취소'
        break
      default:
        parseState = ''
        break
    }
    return parseState
  }
  const parsePayType = type => {
    let parseType
    switch (type) {
      case 'CARD':
        parseType = '카드(비인증)'
        break
      case 'ACCOUNT':
        parseType = '무통장'
        break
      case 'COUPON':
        parseType = '쿠폰'
        break
      case 'POINT':
        parseType = '포인트'
        break
      case 'NICEPAY_AUTH':
        parseType = '나이스페이(인증)'
        break
      case 'NAVERPAY_AUTH':
        parseType = '네이버페이(인증)'
        break
      default:
        parseType = ''
        break
    }
    return parseType
  }
  const toggleUpdateDeliverySidebar = () => {
    showUpdateDeliverySidebar.value = !showUpdateDeliverySidebar.value
  }
  const toggleAddChargeSidebar = () => {
    showAddChargeSidebar.value = !showAddChargeSidebar.value
  }
  const toggleAddPaymentSidebar = () => {
    showAddPaymentSidebar.value = !showAddPaymentSidebar.value
  }
  const toggleTrackingNoSidebar = () => {
    showTrackingNoSidebar.value = !showTrackingNoSidebar.value
  }
  const toggleCancelOrderSidebar = () => {
    showCancelSidebar.value = !showCancelSidebar.value
    if (orderData.value.state === 'ORDERED' || orderData.value.state === 'PAID') {
      step.value.hasTrackingNo = false
      step.value.hasSettle = false
      step.value.inputAmount = false
      step.value.cancelConfirm = true
      step.value.takebackConfirm = false
    } else if (orderData.value.state === 'READY') {
      step.value.hasTrackingNo = true
      step.value.hasSettle = false
      step.value.inputAmount = false
      step.value.cancelConfirm = false
      step.value.takebackConfirm = false
    } else if (orderData.value.state === 'DELIVERY' || orderData.value.state === 'DONE') {
      step.value.hasTrackingNo = false
      step.value.hasSettle = true
      step.value.inputAmount = false
      step.value.cancelConfirm = false
      step.value.takebackConfirm = true
    } else {
      step.value.hasTrackingNo = false
      step.value.hasSettle = false
      step.value.inputAmount = false
      step.value.cancelConfirm = false
      step.value.takebackConfirm = false
    }
  }
  const onHiddenUpdateDeliverySidebar = () => {
    showUpdateDeliverySidebar.value = false
  }
  const onHiddenCancelOrderSidebar = () => {
    showCancelSidebar.value = false
  }
  const onHiddenTrackingNoSidebar = () => {
    showTrackingNoSidebar.value = false
  }
  const onHiddenAddChargeSidebar = () => {
    showAddChargeSidebar.value = false
  }
  const onHiddenAddPaymentSidebar = () => {
    showAddPaymentSidebar.value = false
  }

  const getOrderDetail = callback => store.dispatch('app-invoice/getOrderDetail', { idx: router.currentRoute.params.idx })
    .then(response => {
      const responseData = response.data.data
      console.log(response.data)

      // Data Formatting
      const parseOptionText = JSON.parse(responseData.order.option_text).reverse()
      responseData.order.created_at = moment(responseData.order.created_at).format('YYYY-MM-DD HH:mm:ss')
      responseData.delivery.created_at = responseData.delivery.created_at == null ? '' : moment(responseData.delivery.created_at).format('YYYY-MM-DD HH:mm:ss')
      responseData.delivery.registered_at = responseData.delivery.registered_at == null ? '' : moment(responseData.delivery.registered_at).format('YYYY-MM-DD HH:mm:ss')
      responseData.delivery.start_at = responseData.delivery.start_at == null ? '' : moment(responseData.delivery.start_at).format('YYYY-MM-DD HH:mm:ss')
      responseData.charge.forEach(c => {
        // eslint-disable-next-line no-param-reassign
        c.created_at = moment(c.created_at).format('YYYY-MM-DD HH:mm:ss')
      })
      responseData.pay.forEach(p => {
        // eslint-disable-next-line no-param-reassign
        p.created_at = moment(p.created_at).format('YYYY-MM-DD HH:mm:ss')
        p.pay_type = parsePayType(p.pay_type)
      })
      responseData.delivery.state = parseDeliveryState(responseData.delivery.state)

      orderData.value = responseData.order ?? {}
      responseData.order_group.store = responseData.store ?? {}
      responseData.order_group.shopper = responseData.shopper ?? {}
      vendorData.value = responseData.vendor ?? {}
      orderGroupData.value = [responseData.order_group]
      deliveryData.value = responseData.delivery ?? {}
      chargeData.value = responseData.charge ?? {}
      payData.value = responseData.pay ?? {}
      productData.value = JSON.parse(responseData.order.product_json)
      optionData.value = JSON.parse(responseData.order.option_json)
      optionTextData.value = parseOptionText
    })
    .catch(error => {
      console.log(error)
      if (error.response.status === 404) {
        orderData.value = undefined
        orderGroupData.value = undefined
        deliveryData.value = undefined
        chargeData.value = undefined
        payData.value = undefined
      }
    })
    .finally(() => {
      if (callback != null) {
        callback()
      }
    })

  const cancelOrder = () => {
    store
      .dispatch('app-invoice/cancelOrder', {
        order_group_no: orderGroupData.value[0].order_no,
        order_no_list: '',
      })
      .then(response => {
        console.log(response.data)
        getOrderDetail()
        toast({
          component: ToastificationContent,
          props: {
            title: '주문 취소에 성공하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '주문 취소에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const saveMemo = () => {
    console.log('memo')
    // store.dispatch('app-user/memoInf',
    //   {
    //     idx: router.currentRoute.params.idx,
    //     ...this.memoForm,
    //   })
    //   .then(response => {
    //     this.$emit('on-user-update', response.data.data)
    //   })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       this.saving = false
    //     }
    //   })
    //   .finally(() => {
    //     this.memoForm.content = ''
    //   })
  }

  const shopperName = ref()
  const inputShopperName = value => {
    shopperName.value = value
  }
  const visibleShopperSidebar = ref(false)
  const turnOnShopperSidebar = () => {
    shopperName.value = orderGroupData.value[0].shopper?.name
    visibleShopperSidebar.value = true
  }
  const turnOffShopperSidebar = () => {
    visibleShopperSidebar.value = false
  }
  const isSavingShopperSidebar = ref(false)
  const isValidShopperSidebar = computed(() => Boolean(shopperName.value))
  const updateShopper = () => {
    isSavingShopperSidebar.value = true
    store.dispatch('app-invoice/updateShopper',
      {
        order_idx: router.currentRoute.params.idx,
        name: shopperName.value,
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '구매자 수정에 성공하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'primary',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '구매자 수정에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        getOrderDetail()
        turnOffShopperSidebar()
        isSavingShopperSidebar.value = false
      })
  }

  const visibleRevertCancelOrderModal = ref(false)
  const turnOnRevertCancelOrderModal = () => {
    visibleRevertCancelOrderModal.value = true
  }
  const turnOffRevertCancelOrderModal = () => {
    visibleRevertCancelOrderModal.value = false
  }
  const revertCanceledOrder = () => {
    store.dispatch('app-invoice/revertCanceledOrder',
      {
        order_idx: router.currentRoute.params.idx,
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '취소주문 복구에 성공하였습니다',
            icon: 'AlertTriangleIcon',
            variant: 'primary',
          },
        })
        getOrderDetail()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '취소주문 복구에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    visibleRevertCancelOrderModal,
    turnOnRevertCancelOrderModal,
    turnOffRevertCancelOrderModal,
    revertCanceledOrder,

    toast,
    orderData,
    orderGroupData,
    productData,
    vendorData,
    optionData,
    optionTextData,
    deliveryData,
    chargeData,
    payData,
    orderGroupFields,
    optionTextFields,
    chargeFields,
    payFields,
    parseDeliveryState,
    parsePayType,

    getOrderDetail,
    cancelOrder,
    saveMemo,
    showCancelSidebar,
    step,
    orderNo,
    orderGroupNo,
    showUpdateDeliverySidebar,
    toggleUpdateDeliverySidebar,
    onHiddenUpdateDeliverySidebar,
    toggleCancelOrderSidebar,
    onHiddenCancelOrderSidebar,
    showTrackingNoSidebar,
    toggleTrackingNoSidebar,
    onHiddenTrackingNoSidebar,
    showAddChargeSidebar,
    toggleAddChargeSidebar,
    onHiddenAddChargeSidebar,
    showAddPaymentSidebar,
    toggleAddPaymentSidebar,
    onHiddenAddPaymentSidebar,
    shopperName,
    inputShopperName,
    visibleShopperSidebar,
    turnOnShopperSidebar,
    turnOffShopperSidebar,
    isSavingShopperSidebar,
    isValidShopperSidebar,
    updateShopper,
  }
}
