<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="30%"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            과금
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <div
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="배송비와 관련있습니까"
                >
                  <b-form-radio-group
                    v-model="isShipping"
                    :options="isShippingOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="과금사유"
                >
                  <b-form-input
                    v-model="reason"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="금액"
                >
                  <b-form-input
                    v-model="amount"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  :disabled="!isValidForm"
                  @click="onSave"
                >
                  <b-spinner
                    v-if="isSaving"
                    small
                  />
                  확인
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="hide"
                >
                  취소
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  VBToggle,
  BSidebar,
  BTable,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BTable,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,
    vSelect,
    BSpinner,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: null,
    },
    orderGroupNo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      reason: null,
      amount: null,
      isShipping: true,
      isShippingOptions: [
        { text: '예', value: true },
        { text: '아니오', value: false },
      ],
      isSaving: false,
    }
  },
  computed: {
    isValidForm: {
      get() {
        return this.reason && this.amount && !this.isSaving
      },
    },
  },
  mounted() {

  },
  methods: {
    onHidden() {
      this.reason = null
      this.amount = null
      this.$emit('on-hidden')
    },
    onShown() {
      console.log('shown')
    },
    onSave() {
      this.isSaving = true
      store
        .dispatch('app-invoice/chargeOrder', {
          is_shipping: this.isShipping,
          order_no: this.orderNo,
          order_group_no: this.orderGroupNo,
          reason: this.reason,
          amount: this.amount,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '과금에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.$emit('refresh-detail')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '과금에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
