<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="70%"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            주문 취소
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <div
            v-show="step.hasTrackingNo"
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group label="이미 송장번호를 발급했습니까?">
                  <b-form-radio-group
                    v-model="hasTrackingNo"
                    :options="hasTrackingNoOption"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    @input="onChangeHasTrackingNo"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-show="step.takebackConfirm"
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="고객의 귀책사유 입니까?"
                >
                  <b-form-radio-group
                    v-model="causeCustomer"
                    :options="hasCauseCustomer"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    @input="setSelectedTakebackPrice"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-show="step.cancelConfirm || step.takebackConfirm">
            <div class="sidebar-content">
              <b-table
                ref="cancelTable"
                selectable
                responsive="sm"
                :items="items"
                :fields="fields"
                select-mode="multi"
                :tbody-tr-class="rowClass"
                @row-selected="onTableSelected"
              >
                <template #cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.rowSelected"
                    :disabled="['CANCELING', 'CANCELED', 'TAKEBACK', 'TAKEBACKED'].includes(row.item.state)"
                    plain
                    class="vs-checkbox-con"
                    style="width: 30px"
                    @change="onTableChecked(row)"
                  />
                </template>

                <template #cell(product_name)="data">
                  <div style="width: 250px">
                    {{ data.value }}
                  </div>
                </template>

                <template
                  v-if="step.cancelConfirm"
                  v-slot:custom-foot
                >
                  <b-tr class="table-result">
                    <b-td>상품총액</b-td>
                    <b-td
                      colspan="4"
                      class="text-right text-success"
                    >
                      {{ cancelAmount }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>배송비</b-td>
                    <b-td
                      colspan="4"
                      class="text-right"
                      :class="cancelShippingFee >= 0 ? 'text-success': 'text-danger'"
                    >
                      {{ cancelShippingFee }}
                    </b-td>
                  </b-tr>
                  <b-tr class="table-result">
                    <b-td>취소총액</b-td>
                    <b-td
                      colspan="4"
                      class="text-right"
                    >
                      {{ cancelAmount + cancelShippingFee }}
                    </b-td>
                  </b-tr>
                </template>

                <template
                  v-else-if="step.takebackConfirm"
                  v-slot:custom-foot
                >
                  <b-tr class="table-result">
                    <b-td>상품총액</b-td>
                    <b-td
                      colspan="4"
                      class="text-right text-success"
                    >
                      {{ takebackAmount }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>배송비</b-td>
                    <b-td
                      colspan="4"
                      class="text-right"
                      :class="takebackShippingFee >= 0 ? 'text-success': 'text-danger'"
                    >
                      {{ takebackShippingFee }}
                    </b-td>
                  </b-tr>
                  <b-tr class="table-result">
                    <b-td>환불가능총액</b-td>
                    <b-td
                      colspan="4"
                      class="text-right"
                    >
                      {{ takebackAmount + takebackShippingFee }}
                    </b-td>
                  </b-tr>
                </template>
              </b-table>
            </div>
          </div>
          <div
            v-show="false"
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="이미 고객이 벤더와 정산을 완료했습니까?"
                >
                  <b-form-radio-group
                    v-model="hasSettle"
                    :options="hasSettleOption"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    @input="onChangeHasSettle"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-show="step.takebackConfirm && causeCustomer"
            class="mb-1"
          >
            <b-row>
              <b-col>
                <b-form-group
                  :label="validateComment"
                >
                  <b-form-input
                    v-model="inputAmount"
                    :state="validInputAmount"
                    trim
                  />
                  <b-form-invalid-feedback>
                    환불가능총액보다 큰 값을 요구할 수 없습니다
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-show="step.takebackConfirm">
            <b-row>
              <b-col>
                <b-button
                  v-b-modal.modal-takeback
                  variant="outline-primary"
                  class="mr-1"
                  :disabled="!isValidForm"
                >
                  환불
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-show="step.cancelConfirm">
            <b-row>
              <b-col>
                <b-button
                  v-b-modal.modal-cancel
                  variant="outline-primary"
                  class="mr-1"
                  :disabled="!isValidForm"
                >
                  주문취소
                </b-button>
              </b-col>
            </b-row>
          </div>

        </div>
      </template>
    </b-sidebar>
    <b-modal
      id="modal-cancel"
      ok-title="확인"
      ok-variant="danger"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-danger"
      centered
      title="주문 취소"
      @ok="cancelOrder"
    >
      주문취소를 진행하시겠습니까
    </b-modal>

    <b-modal
      id="modal-takeback"
      ok-title="확인"
      ok-variant="danger"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-danger"
      centered
      title="주문 환불"
      @ok="takebackOrder"
    >
      주문환불을 진행하시겠습니까
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  VBToggle,
  BSidebar,
  BTable,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  VBModal,
  BTr,
  BTd,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import orderStoreModule from '@/views/commerce/orderStoreModule'
import orderViewModel from '@/views/commerce/OrderViewModel'

const EDITABLE_STATE = ['ORDERED', 'PAID', 'READY', 'DELIVERY', 'DONE']

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BTable,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,
    BTr,
    BTd,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: null,
    },
    orderGroupNo: {
      type: String,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'selected', label: '선택' },
        { key: 'order_no', label: '주문번호' },
        { key: 'product_name', label: '상품명' },
        { key: 'state', label: '상태' },
        { key: 'total_amount', label: '가격' },
      ],
      items: [],
      selected: [],
      cancelAmount: 0,
      cancelShippingFee: 0,
      takebackAmount: 0,
      takebackShippingFee: 0,
      hasTrackingNo: null,
      hasTrackingNoOption: [
        { text: '예', value: true, disabled: false },
        { text: '아니오', value: false, disabled: false },
      ],
      hasSettle: null,
      hasSettleOption: [
        { text: '예', value: true, disabled: false },
        { text: '아니오', value: false, disabled: false },
      ],
      causeCustomer: true,
      hasCauseCustomer: [
        { text: '예', value: true, disabled: false },
        { text: '아니오', value: false, disabled: false },
      ],
      inputAmount: null,
      validateComment: '고객에게 요구할 반송 배송비를 입력해주십시오',
    }
  },
  computed: {
    validInputAmount: {
      get() {
        const inputAmount = parseInt(this.inputAmount, 10)
        return inputAmount >= 0 && inputAmount <= (this.takebackAmount + this.takebackShippingFee)
      },
    },
    isValidForm: {
      get() {
        if (this.step.cancelConfirm && this.selected.length > 0) {
          return true
        }
        if (this.step.takebackConfirm && this.selected.length > 0 && this.causeCustomer && this.validInputAmount) {
          return true
        }
        if (this.step.takebackConfirm && this.selected.length > 0 && !this.causeCustomer) {
          return true
        }
        return false
      },
    },
  },
  mounted() {
    console.log(this.orderNo)
    console.log(this.orderGroupNo)
    console.log('mounted')
  },
  methods: {
    onHidden() {
      this.items = []
      this.selected = []
      this.cancelAmount = 0
      this.cancelShippingFee = 0
      this.hasTrackingNo = null
      this.hasSettle = null
      this.inputAmount = null
      this.$emit('on-hidden')
    },
    onShown() {
      console.log('shown')
      if (this.step.cancelConfirm || this.step.takebackConfirm) {
        this.setCancelConfirmTable()
      }
    },
    setCancelConfirmTable() {
      this.items = []
      store
        .dispatch('app-invoice/fetchOrderGroup', {
          order_group_no: this.orderGroupNo,
        })
        .then(response => {
          this.items = response.data.data
        })
        .catch(() => {
          this.step.value.hasTrackingNo = false
          this.step.value.hasSettle = false
          this.step.value.inputAmount = false
          this.step.value.cancelConfirm = false
          this.step.value.takebackConfirm = false
        })
        .finally(() => {
          const i = this.items.findIndex(item => item.order_no === this.orderNo)
          this.$refs.cancelTable.selectRow(i)
        })
    },
    setSelectedCancelPrice() {
      store
        .dispatch('app-invoice/fetchSelectedCancelPrice', {
          order_group_no: this.orderGroupNo,
          order_no_list: this.selected.map(order => order.order_no),
        })
        .then(response => {
          console.log(response.data)
          this.cancelShippingFee = response.data.data.shipping_fee
          this.cancelAmount = response.data.data.total_amount + response.data.data.total_vat
          // this.cancelAmount = this.selected.reduce((p, c) => p + c.total_amount, 0)
        })
        .catch(() => {
          this.step.value.hasTrackingNo = false
          this.step.value.hasSettle = false
          this.step.value.inputAmount = false
          this.step.value.cancelConfirm = false
          this.step.value.takebackConfirm = false
        })
    },
    setSelectedTakebackPrice() {
      store
        .dispatch('app-invoice/fetchSelectedTakebackPrice', {
          order_group_no: this.orderGroupNo,
          order_no_list: this.selected.map(order => order.order_no),
          cause_customer: this.causeCustomer,
        })
        .then(response => {
          this.takebackShippingFee = response.data.data.shipping_fee
          this.takebackAmount = response.data.data.total_amount + response.data.data.total_vat
          // this.cancelAmount = this.selected.reduce((p, c) => p + c.total_amount, 0)
        })
        .catch(() => {
          this.step.value.hasTrackingNo = false
          this.step.value.hasSettle = false
          this.step.value.inputAmount = false
          this.step.value.cancelConfirm = false
          this.step.value.takebackConfirm = false
        })
    },
    setSelectedPrice() {
      if (this.step.cancelConfirm) {
        this.setSelectedCancelPrice()
      } else if (this.step.takebackConfirm) {
        this.setSelectedTakebackPrice()
      }
    },
    onTableSelected(items) {
      const disableItem = items.find(item => !(EDITABLE_STATE.includes(item.state)))
      if (disableItem == null) {
        this.selected = items
        this.setSelectedPrice()
      } else {
        const i = this.items.findIndex(item => item.order_no === disableItem.order_no)
        this.$refs.cancelTable.unselectRow(i)
      }
    },
    onChangeHasTrackingNo() {
      if (this.hasTrackingNo) {
        this.step.hasTrackingNo = true
        this.step.hasSettle = true
        this.step.inputAmount = false
        this.step.cancelConfirm = false
        this.step.takebackConfirm = true
        this.hasSettle = null
      } else {
        this.step.hasTrackingNo = true
        this.step.hasSettle = false
        this.step.inputAmount = false
        this.step.cancelConfirm = true
        this.step.takebackConfirm = false
      }
      this.setCancelConfirmTable()
    },
    onChangeHasSettle() {
      if (this.hasSettle === true) {
        this.validateComment = '정산이 완료된 금액을 입력해주십시오'
        this.step.inputAmount = true
      } else if (this.hasSettle === false) {
        this.validateComment = '고객에게 요구할 반송 배송비를 입력해주십시오'
        this.step.inputAmount = true
      } else {
        this.validateComment = ''
        this.step.inputAmount = false
      }
    },
    onTableChecked(row) {
      const i = this.items.findIndex(item => item.order_no === row.item.order_no)
      if (EDITABLE_STATE.includes(row.item.state) && row.rowSelected) {
        this.$refs.cancelTable.selectRow(i)
      } else {
        this.$refs.cancelTable.unselectRow(i)
      }
    },
    cancelOrder() {
      store
        .dispatch('app-invoice/cancelOrder', {
          order_group_no: this.orderGroupNo,
          order_no_list: this.selected.map(order => order.order_no),
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '주문 취소에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '주문 취소에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.setCancelConfirmTable()
          this.$emit('refresh-detail')
        })
    },
    takebackOrder() {
      store
        .dispatch('app-invoice/takebackOrder', {
          order_group_no: this.orderGroupNo,
          order_no_list: this.selected.map(order => order.order_no),
          add_cancel_price: this.inputAmount,
          cause_customer: this.causeCustomer,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '주문 취소에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '주문 취소에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.setCancelConfirmTable()
          this.$emit('refresh-detail')
        })
    },
    rowClass(item, type) {
      if (!EDITABLE_STATE.includes(item.state)) { return 'table-canceled' }
      return ''
    },
  },
}
</script>

<style scoped>
.sidebar-content {
  overflow-x: auto;
}
.table-canceled {
  cursor: not-allowed;
  color: lightgray;
}
.table-result {
  border-top: 5px double;
}
</style>
