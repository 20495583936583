<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orderData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching Order data
      </h4>
      <div class="alert-body">
        No Order found with this Order idx. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Order List
        </b-link>
        for other orders.
      </div>
    </b-alert>

    <b-row
      v-if="orderData"
      class="invoice-preview"
    >

      <!--  -->
      <b-col
        cols="12"
        lg="10"
      >
        <b-row>
          <b-col
            cols="12"
            lg="12"
          >
            <b-card
              no-body
              class="overflow-auto"
            >
              <b-table
                :fields="orderGroupFields"
                :items="orderGroupData"
              >
                <template #cell(order_no)="data">
                  <b-link :to="{name: 'commerce-order-list', query: {order_no: data.value }}">
                    {{ data.value }}
                  </b-link>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-clipboard:copy="data.value"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="makeCopyToast(data.value)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </template>
                <template #cell(store)="data">
                  <div
                    style="min-width: 100px"
                  >
                    <b-link
                      :to="{name: 'commerce-mystore-view', params: {idx: data.item.store.idx}}"
                    >
                      <div>{{ data.item.store.name }}</div>
                      <div> ({{ data.item.store.url_path }})</div>
                    </b-link>
                  </div>
                </template>
                <template #cell(total_amount)="data">
                  {{ data.value + data.item.vat }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.shopper.name }}
                </template>
                <template #cell(phone)="data">
                  {{ data.item.shopper.phone }}
                </template>
              </b-table>

              <!-- Spacer -->
              <!-- <hr class="invoice-spacing"> -->

              <!-- Note
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
                  projects. Thank You!</span>
              </b-card-body>
              -->
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >

            <b-card
              title="Order Info"
              class="invoice-preview-card"
            >
              <!-- 상품정보 -->
              <div class="d-flex align-items-center">
                <div style="width: 40%;">
                  <img
                    style="width: 106px; border-radius: 4px;"
                    :src="orderData.product_thumb_img"
                  >
                </div>
                <div
                  class="ml-1"
                  style="width: 60%;"
                >
                  <small>태그바이 품번</small>
                  <h5 class="mb-0">
                    <b-link :to="{name: 'commerce-product-detail', params: {idx: productData.product_idx}}">
                      {{ optionData.product_no }}
                    </b-link>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-clipboard:copy="optionData.product_no"
                      variant="flat-primary"
                      class="btn-icon"
                      @click="makeCopyToast(optionData.product_no)"
                    >
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                  </h5>
                  <small>벤더</small>
                  <h5 class="mb-0">
                    {{ vendorData.company_name }} ( {{ vendorData.idx }} / {{ vendorData.legacy_vendor_no }} )
                  </h5>
                  <small>주문번호</small>
                  <h5 class="mb-0">
                    {{ orderData.order_no }}
                  </h5>
                  <template v-if="orderData.personal_customs_id">
                    <small>개인통관번호</small>
                    <h5
                      class="mb-0"
                      style="color: lightgreen"
                    >
                      {{ orderData.personal_customs_id }}
                    </h5>
                  </template>
                </div>
              </div>

              <hr>

              <!-- 옵션정보 -->
              <div class="align-items-center">
                <div style="width: 100%;">
                  <small>옵션</small>
                  <b-table
                    responsive="sm"
                    small
                    :fields="optionTextFields"
                    :items="optionTextData"
                  />
                </div>
              </div>

              <hr>

              <!-- 주문정보 -->
              <div class="d-flex align-items-center mr-2">
                <div
                  class="ml-1"
                  style="width: 50%;"
                >
                  <small>주문일시</small>
                  <h5 class="mb-0">
                    {{ orderData.created_at }}
                  </h5>
                </div>
                <div
                  class="ml-1"
                  style="width: 30%;"
                >
                  <small>Amount</small>
                  <h5 class="mb-0">
                    {{ (orderData.amount + orderData.vat).toLocaleString() }}
                  </h5>
                </div>
                <div
                  class="ml-1"
                  style="width: 20%;"
                >
                  <small>수량</small>
                  <h5 class="mb-0">
                    {{ orderData.qty }}
                  </h5>
                </div>
              </div>

              <b-row
                v-if="orderData.reason"
                class="mt-2"
              >
                <b-col class="text-center">
                  <h6>취소사유: {{ orderData.reason }}</h6>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <!-- 배송정보 -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-card
              title="Delivery Info"
              class="invoice-preview-card"
            >

              <!-- Header: Left Content -->
              <div class="ml-1">
                <small>주소</small>
                <p class="card-text mb-25">
                  ( {{ deliveryData.zipcode }} ) {{ deliveryData.address }} {{ deliveryData.address_detail }}
                </p>
                <p class="card-text mb-25">
                  {{ deliveryData.recipient_name }} ( {{ deliveryData.phone }} )
                </p>
                <p class="card-text mb-0">
                  메세지 : {{ deliveryData.message }}
                </p>
              </div>

              <hr>

              <div class="ml-1">
                <small>송장</small>
                <p class="card-text mb-25">
                  ( {{ deliveryData.shipment_company }} ) {{ deliveryData.tracking_no }}
                </p>
                <p class="card-text mb-25">
                  [ {{ deliveryData.state }} ]
                </p>
                <!--                <b-badge pill variant="light-success">Success</b-badge>-->
              </div>

              <hr>

              <div class="d-flex align-items-center mr-2">
                <div
                  class="ml-1"
                  style="width: 50%;"
                >
                  <small>송장입력일</small>
                  <h5 class="mb-0">
                    {{ deliveryData.registered_at }}
                  </h5>
                </div>
                <div
                  class="ml-1"
                  style="width: 50%;"
                >
                  <small>출고일</small>
                  <h5 class="mb-0">
                    {{ deliveryData.start_at }}
                  </h5>
                </div>
              </div>

            </b-card>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-table
              responsive="sm"
              small
              :sort-by.sync="sortKey"
              :fields="chargeFields"
              :items="chargeData"
            >
              <template #cell(title)="data">
                <div :class="{'line-through': data.item.state === 'FAIL'}">
                  {{ data.value }}
                </div>
              </template>
              <template #cell(amount)="data">
                <div :class="{'line-through': data.item.state === 'FAIL'}">
                  {{ (data.value + data.item.vat).toLocaleString() }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div
                  style="max-width: 90px;"
                  :class="{'line-through': data.item.state === 'FAIL'}"
                >
                  {{ data.value }}
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <b-table
              responsive="sm"
              small
              :sort-by.sync="sortKey"
              :fields="payFields"
              :items="payData"
            >
              <template #cell(amount)="data">
                {{ (data.value + data.item.vat).toLocaleString() }}
              </template>
              <template #cell(created_at)="data">
                <div style="max-width: 90px;">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  button
                  size="sm"
                  button-variant="outline-primary"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card
                  v-if="row.item.meta_data"
                  no-body
                >
                  <b-row>
                    <b-col>
                      <strong>은행 이름 : </strong>{{ row.item.meta_data.bank_name }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>계좌 번호 : </strong>{{ row.item.meta_data.bank_no }}
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-clipboard:copy="row.item.meta_data.bank_no"
                        variant="flat-primary"
                        class="btn-icon"
                        style="padding:0"
                        @click="makeCopyToast(row.item.meta_data.bank_no)"
                      >
                        <feather-icon icon="CopyIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>예금주 : </strong>{{ row.item.meta_data.bank_holder }}
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  v-else-if="row.item.depositor_name"
                >
                  <b-row>
                    <b-col>
                      <strong>입금자: </strong> {{ row.item.depositor_name }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>receipt type: </strong> {{ row.item.cash_receipt_type }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>receipt id type: </strong> {{ row.item.cash_receipt_id_type }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>receipt id: </strong> {{ row.item.cash_receipt_id }}
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  v-else-if="row.item.pid"
                >
                  PID: {{ row.item.pid }}
                  <tagby-copy-icon :value="row.item.pid" />
                </b-card>
                <b-card
                  v-else
                  no-body
                  class="d-flex flex-row justify-content-center"
                >
                  <span>
                    없음
                  </span>
                </b-card>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-card title="메모">
              <b-row
                v-for="memo in memos"
                :key="memo.idx"
                class="ml-0"
              >
                <div>{{ memo.text }}</div>
                <div class="blockquote-footer">
                  ({{ memo.admin.name }})[{{ resolveDatetime(memo.created_at) }}]
                </div>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- 버튼 -->
      <b-col
        cols="12"
        lg="2"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="orderData.state !== 'PAID' || orderGroupData[0].member_type !== 'SHP'"
            @click="turnOnShopperSidebar"
          >
            구매자 수정
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="toggleUpdateDeliverySidebar"
          >
            배송정보 수정
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-paid
            variant="outline-primary"
            block
            :disabled="orderData.state !== 'ORDERED'"
          >
            결제완료
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="!['PAID', 'READY', 'DELIVERY', 'DONE'].includes(orderData.state)"
            @click="toggleTrackingNoSidebar"
          >
            송장번호 수정
          </b-button>
          <b-button
            v-b-modal.modal-reset-delivery
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="!['DELIVERY', 'DONE'].includes(orderData.state)"
          >
            송장번호 초기화
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-done
            variant="outline-primary"
            block
            :disabled="!['PAID', 'READY', 'DELIVERY'].includes(orderData.state)"
          >
            배송완료
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="!['ORDERED', 'PAID', 'READY', 'DELIVERY', 'DONE'].includes(orderData.state)"
            @click="toggleCancelOrderSidebar"
          >
            주문취소
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-canceled
            variant="outline-primary"
            block
            :disabled="orderData.state !== 'CANCELING'"
          >
            취소완료
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="['REGISTER', 'ORDERED'].includes(orderData.state)"
            @click="toggleAddChargeSidebar"
          >
            과금
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="['REGISTER', 'ORDERED'].includes(orderData.state)"
            @click="toggleAddPaymentSidebar"
          >
            결제
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="!['CANCELED'].includes(orderData.state)"
            @click="turnOnRevertCancelOrderModal"
          >
            취소주문 복구
          </b-button>
          <b-button
            v-b-modal.modal-reset-password
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            비밀번호 초기화
          </b-button>
          <b-button
            v-b-modal.tagby-memo
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            메모
          </b-button>
        </b-card>
      </b-col>

    </b-row>

    <!-- <invoice-sidebar-send-invoice /> -->
    <invoice-sidebar-update-delivery
      :show-sidebar="showUpdateDeliverySidebar"
      :deliveryIdx="deliveryData.idx"
      :address="deliveryData.address"
      :addressDetail="deliveryData.address_detail"
      :recipientName="deliveryData.recipient_name"
      :message="deliveryData.message"
      :phone="deliveryData.phone"
      :personalCustomsId="orderData.personal_customs_id"
      @on-hidden="onHiddenUpdateDeliverySidebar"
      @refresh-detail="getOrderDetail"
    />
    <invoice-sidebar-add-charge
      :show-sidebar="showAddChargeSidebar"
      :order-no="orderNo"
      :order-group-no="orderGroupNo"
      @on-hidden="onHiddenAddChargeSidebar"
      @refresh-detail="getOrderDetail"
    />
    <invoice-sidebar-add-payment
      :show-sidebar="showAddPaymentSidebar"
      :order-no="orderNo"
      :order-group-no="orderGroupNo"
      @on-hidden="onHiddenAddPaymentSidebar"
      @refresh-detail="getOrderDetail"
    />
    <invoice-sidebar-tracking-no
      v-if="deliveryData"
      :show-sidebar="showTrackingNoSidebar"
      :order-no="orderNo"
      :shipment-company="deliveryData.shipment_company"
      :tracking-no="deliveryData.tracking_no"
      @on-hidden="onHiddenTrackingNoSidebar"
      @refresh-detail="getOrderDetail"
    />
    <invoice-sidebar-cancel-confirm
      :show-sidebar="showCancelSidebar"
      :order-no="orderNo"
      :order-group-no="orderGroupNo"
      :step="step"
      @on-hidden="onHiddenCancelOrderSidebar"
      @refresh-detail="getOrderDetail"
    />

    <b-modal
      id="modal-paid"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="결제완료로 변경"
      @ok="paidOrder"
    >
      같은 주문그룹의 결제대기 상태인 모든 주문이 결제완료로 변경됩니다. 변경하시겠습니까?
    </b-modal>

    <b-modal
      id="modal-done"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="배송완료로 변경"
      @ok="doneOrder"
    >
      해당주문만 배송완료로 변경합니다
    </b-modal>

    <b-modal
      id="modal-canceled"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="취소완료로 변경"
      @ok="canceledOrder"
    >
      같은 주문그룹의 취소중 상태인 모든 주문이 취소완료로 변경됩니다. 변경하시겠습니까?
    </b-modal>

    <b-modal
      id="modal-reset-password"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="비밀번호 리셋"
      @ok="resetPassword"
    >
      해당 주문그룹의 비밀번호가 123456으로 변경됩니다. 변경하시겠습니까?
    </b-modal>

    <b-modal
      id="modal-reset-delivery"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="송장번호 초기화"
      @ok="resetDelivery"
    >
      해당 주문의 송장번호가 초기화 됩니다. 변경하시겠습니까?
    </b-modal>

    <tagby-memo
      v-if="orderData"
      table="order_info"
      :idx="orderData.idx"
      :saveThen="getOrderMemos"
    />

    <update-shopper-sidebar
      :visible="visibleShopperSidebar"
      :is-saving="isSavingShopperSidebar"
      :is-valid="isValidShopperSidebar"
      :shopper-name="shopperName"
      @input-shopper-name="inputShopperName"
      @ok="updateShopper"
      @hidden="turnOffShopperSidebar"
    />

    <TagbyPrimaryModal
      :visible="visibleRevertCancelOrderModal"
      title="취소주문 복구"
      content="취소된 주문이 결제대기 상태로 복구됩니다. 결제가 완료되었다면 결제완료 버튼을 눌러 결제완료처리까지 해주세요"
      @ok="revertCanceledOrder"
      @hidden="turnOffRevertCancelOrderModal"
    />

  </section>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BTable,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import moment from 'moment-timezone'
import store from '@/store'
import orderStoreModule from './orderStoreModule'
import orderViewModel from '@/views/commerce/OrderViewModel'
import InvoiceSidebarUpdateDelivery from './InvoiceSidebarUpdateDelivery.vue'
import InvoiceSidebarCancelConfirm from './InvoiceSidebarCancelConfirm.vue'
import InvoiceSidebarTrackingNo from './InvoiceSidebarTrackingNo.vue'
import InvoiceSidebarAddCharge from './InvoiceSidebarAddCharge.vue'
import InvoiceSidebarAddPayment from './InvoiceSidebarAddPayment.vue'
import TagbyMemo from '@/components/TagbyMemo/TagbyMemo.vue'
import router from '@/router'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import UpdateShopperSidebar from './UpdateShopperSidebar.vue'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BLink,
    BTable,
    BModal,
    BFormCheckbox,
    InvoiceSidebarUpdateDelivery,
    InvoiceSidebarTrackingNo,
    InvoiceSidebarCancelConfirm,
    InvoiceSidebarAddCharge,
    InvoiceSidebarAddPayment,
    TagbyMemo,
    TagbyCopyIcon,
    UpdateShopperSidebar,
    TagbyPrimaryModal,
  },
  data() {
    return {
      sortKey: 'created_at',
      memos: [],
    }
  },
  mounted() {
    this.getOrderDetail()
    this.getOrderMemos()
  },
  methods: {
    makeCopyToast(text) {
      if (text !== null && text !== '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy Success',
            icon: 'BellIcon',
            text,
            variant: 'primary',
          },
        })
      }
    },
    paidOrder() {
      store
        .dispatch('app-invoice/paidOrder', {
          order_no: this.orderNo,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '결제완료 변경에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.getOrderDetail()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '결제완료 변경에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    paymentOrder() {
      store
        .dispatch('app-invoice/paymentOrder', {
          order_no: this.orderNo,
          order_group_no: this.orderGroupNo,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '결제에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.getOrderDetail()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '결제에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    doneOrder() {
      store
        .dispatch('app-invoice/doneOrder', {
          order_no: this.orderNo,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '배송완료에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.getOrderDetail()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '배송완료에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    canceledOrder() {
      store
        .dispatch('app-invoice/canceledOrder', {
          order_no: this.orderNo,
        })
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '취소완료에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          this.getOrderDetail()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '취소완료에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getOrderMemos() {
      store
        .dispatch('app-invoice/fetchOrderMemos', {
          idx: router.currentRoute.params.idx,
        })
        .then(response => {
          this.memos = response.data.data
        })
        .catch(() => {
        })
    },
    resolveDatetime(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
    },
    resetPassword() {
      store
        .dispatch('app-invoice/resetPassword', {
          idx: router.currentRoute.params.idx,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '비밀번호 리셋에 성공했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'primary',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '비밀번호 리셋에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resetDelivery() {
      store
        .dispatch('app-invoice/resetDelivery', {
          idx: router.currentRoute.params.idx,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '송장번호 초기화에 성공했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'primary',
            },
          })
          this.getOrderDetail()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '송장번호 초기화에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  //
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      orderData,
      orderGroupData,
      productData,
      vendorData,
      optionData,
      optionTextData,
      deliveryData,
      chargeData,
      payData,
      orderGroupFields,
      optionTextFields,
      chargeFields,
      payFields,
      getOrderDetail,
      cancelOrder,
      showCancelSidebar,
      step,
      orderNo,
      orderGroupNo,
      showUpdateDeliverySidebar,
      toggleUpdateDeliverySidebar,
      onHiddenUpdateDeliverySidebar,
      toggleCancelOrderSidebar,
      onHiddenCancelOrderSidebar,
      showTrackingNoSidebar,
      toggleTrackingNoSidebar,
      onHiddenTrackingNoSidebar,
      showAddChargeSidebar,
      toggleAddChargeSidebar,
      onHiddenAddChargeSidebar,
      showAddPaymentSidebar,
      toggleAddPaymentSidebar,
      onHiddenAddPaymentSidebar,
      shopperName,
      inputShopperName,
      visibleShopperSidebar,
      turnOnShopperSidebar,
      turnOffShopperSidebar,
      isSavingShopperSidebar,
      isValidShopperSidebar,
      updateShopper,
      visibleRevertCancelOrderModal,
      turnOnRevertCancelOrderModal,
      turnOffRevertCancelOrderModal,
      revertCanceledOrder,
    } = orderViewModel()

    return {
      visibleRevertCancelOrderModal,
      turnOnRevertCancelOrderModal,
      turnOffRevertCancelOrderModal,
      revertCanceledOrder,
      orderData,
      orderGroupData,
      vendorData,
      deliveryData,
      chargeData,
      payData,
      productData,
      optionData,
      optionTextData,

      orderGroupFields,
      chargeFields,
      payFields,
      optionTextFields,

      orderStoreModule,
      cancelOrder,
      showCancelSidebar,
      step,
      orderNo,
      orderGroupNo,
      toggleCancelOrderSidebar,
      onHiddenCancelOrderSidebar,
      getOrderDetail,
      showUpdateDeliverySidebar,
      toggleUpdateDeliverySidebar,
      onHiddenUpdateDeliverySidebar,
      showTrackingNoSidebar,
      toggleTrackingNoSidebar,
      onHiddenTrackingNoSidebar,
      showAddChargeSidebar,
      toggleAddChargeSidebar,
      onHiddenAddChargeSidebar,
      showAddPaymentSidebar,
      toggleAddPaymentSidebar,
      onHiddenAddPaymentSidebar,
      shopperName,
      inputShopperName,
      visibleShopperSidebar,
      turnOnShopperSidebar,
      turnOffShopperSidebar,
      isSavingShopperSidebar,
      isValidShopperSidebar,
      updateShopper,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style scoped>
.line-through {
  text-decoration: line-through;
}
</style>
